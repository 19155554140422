<template>
  <div class="disc-dice">
    <transition name="slide-fade">
      <div class="active-game" key="game" v-if="ongoingGame">
        <div class="active-header">
          <p class="back" @click="endGame()">
            <Chevron2 direction="left" />Back
          </p>
          <p class="active-title"><b>Hasard</b></p>
        </div>

        <transition name="discfade" mode="out-in">
          <p class="sub-text large" v-if="generatedThrow == null" key="place1">
            Good luck, you'll probably need it throwing these shots!
          </p>
          <div v-else-if="shotLoading" class="loader" key="place2">
            <div class="lds-default">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="generated-shot" v-else key="place3">
            <h4 class="throw-text large">Throw</h4>
            <h3>
              {{ generatedThrow.release.name }}
              {{ generatedThrow.shotType.name }}
            </h3>
            <h4 class="throw-text second">with</h4>
            <h3>
              {{ generatedThrow.stability.name }} {{ generatedThrow.disc.name }}
            </h3>
          </div>
        </transition>
        <div class="generate-throw">
          <StandardSolidButton
            title="Next throw"
            :fluid="false"
            @click="generateThrow()"
          />
        </div>
      </div>
      <div class="preferences" v-else key="pref">
        <h1>Hasard</h1>
        <p class="sub-text">
          Set which throws and discs to include and tweak their frequency
        </p>

        <h3>Shots <span>Frequency</span></h3>
        <ul>
          <li v-for="shot in shotTypes" :class="{ disabled: !shot.enabled }">
            <div class="status">
              <DefaultCheckBox
                :value="shot.enabled"
                :disabled="false"
                @input="shot.enabled = $event"
              />
            </div>
            <div class="name" @click="shot.enabled = !shot.enabled">
              <h5>{{ shot.name }}</h5>
            </div>
            <div class="occurence">
              <div
                class="button very-low"
                @click="adjustOccurance(shot)"
                v-if="shot.occurance == 1"
              >
                <p>Very low</p>
              </div>
              <div
                class="button low"
                @click="adjustOccurance(shot)"
                v-else-if="shot.occurance == 2"
              >
                <p>Low</p>
              </div>
              <div
                class="button medium"
                @click="adjustOccurance(shot)"
                v-else-if="shot.occurance == 3"
              >
                <p>Medium</p>
              </div>
              <div
                class="button high"
                @click="adjustOccurance(shot)"
                v-else-if="shot.occurance == 4"
              >
                <p>High</p>
              </div>
              <div
                class="button very-high"
                @click="adjustOccurance(shot)"
                v-else-if="shot.occurance == 5"
              >
                <p>Very high</p>
              </div>
            </div>
          </li>
        </ul>
        <h3>Releases <span>Frequency</span></h3>
        <ul>
          <li
            v-for="release in shotReleases"
            :class="{ disabled: !release.enabled }"
          >
            <div class="status">
              <DefaultCheckBox
                :value="release.enabled"
                :disabled="false"
                @input="release.enabled = $event"
              />
            </div>
            <div class="name" @click="release.enabled = !release.enabled">
              <h5>{{ release.name }}</h5>
            </div>
            <div class="occurence">
              <div
                class="button very-low"
                @click="adjustOccurance(release)"
                v-if="release.occurance == 1"
              >
                <p>Very low</p>
              </div>
              <div
                class="button low"
                @click="adjustOccurance(release)"
                v-else-if="release.occurance == 2"
              >
                <p>Low</p>
              </div>
              <div
                class="button medium"
                @click="adjustOccurance(release)"
                v-else-if="release.occurance == 3"
              >
                <p>Medium</p>
              </div>
              <div
                class="button high"
                @click="adjustOccurance(release)"
                v-else-if="release.occurance == 4"
              >
                <p>High</p>
              </div>
              <div
                class="button very-high"
                @click="adjustOccurance(release)"
                v-else-if="release.occurance == 5"
              >
                <p>Very high</p>
              </div>
            </div>
          </li>
        </ul>

        <h3>Discs <span>Frequency</span></h3>
        <ul>
          <li v-for="disc in discTypes" :class="{ disabled: !disc.enabled }">
            <div class="status">
              <DefaultCheckBox
                :value="disc.enabled"
                :disabled="false"
                @input="disc.enabled = $event"
              />
            </div>
            <div class="name" @click="disc.enabled = !disc.enabled">
              <h5>{{ disc.name }}</h5>
            </div>
            <div class="occurence">
              <div
                class="button very-low"
                @click="adjustOccurance(disc)"
                v-if="disc.occurance == 1"
              >
                <p>Very low</p>
              </div>
              <div
                class="button low"
                @click="adjustOccurance(disc)"
                v-else-if="disc.occurance == 2"
              >
                <p>Low</p>
              </div>
              <div
                class="button medium"
                @click="adjustOccurance(disc)"
                v-else-if="disc.occurance == 3"
              >
                <p>Medium</p>
              </div>
              <div
                class="button high"
                @click="adjustOccurance(disc)"
                v-else-if="disc.occurance == 4"
              >
                <p>High</p>
              </div>
              <div
                class="button very-high"
                @click="adjustOccurance(disc)"
                v-else-if="disc.occurance == 5"
              >
                <p>Very high</p>
              </div>
            </div>
          </li>
        </ul>

        <h3>Stability <span>Frequency</span></h3>

        <ul>
          <li
            v-for="stability in discStabilities"
            :class="{ disabled: !stability.enabled }"
          >
            <div class="status">
              <DefaultCheckBox
                :value="stability.enabled"
                :disabled="false"
                @input="stability.enabled = $event"
              />
            </div>
            <div class="name" @click="stability.enabled = !stability.enabled">
              <h5>{{ stability.name }}</h5>
            </div>
            <div class="occurence">
              <div
                class="button very-low"
                @click="adjustOccurance(stability)"
                v-if="stability.occurance == 1"
              >
                <p>Very low</p>
              </div>
              <div
                class="button low"
                @click="adjustOccurance(stability)"
                v-else-if="stability.occurance == 2"
              >
                <p>Low</p>
              </div>
              <div
                class="button medium"
                @click="adjustOccurance(stability)"
                v-else-if="stability.occurance == 3"
              >
                <p>Medium</p>
              </div>
              <div
                class="button high"
                @click="adjustOccurance(stability)"
                v-else-if="stability.occurance == 4"
              >
                <p>High</p>
              </div>
              <div
                class="button very-high"
                @click="adjustOccurance(stability)"
                v-else-if="stability.occurance == 5"
              >
                <p>Very high</p>
              </div>
            </div>
          </li>
        </ul>
        <p class="game-not-startable" v-if="!gameIsStartable">
          Please ensure atleast one item is enabled in each category.
        </p>
        <div class="start-game">
          <StandardSolidButton
            title="Start game"
            :fluid="false"
            :disabled="!gameIsStartable"
            @click="startGame()"
          />
          <p class="reset-preferences" @click="resetPreferences()">
            Reset preferences
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import DefaultCheckBox from "@/components/UIElements/DefaultCheckBox";
import Chevron2 from "@/assets/icons/Chevron2";
export default {
  name: "Hasard",
  data() {
    return {
      gameVersion: 1,
      ongoingGame: false,
      shotTypes: [],
      shotReleases: [],
      discTypes: [],
      discStabilities: [],
      generatedThrow: null,
      shotLoading: false,
    };
  },
  components: {
    StandardSolidButton,
    DefaultCheckBox,
    Chevron2,
  },
  watch: {
    $data: {
      handler: function (newVal, oldVal) {
        this.saveGameState();
      },
      deep: true,
    },
  },
  computed: {
    gameIsStartable() {
      let gameIsStartable = true;

      if (this.discs.length == 0) gameIsStartable = false;
      if (this.releases.length == 0) gameIsStartable = false;
      if (this.shots.length == 0) gameIsStartable = false;
      if (this.stabilities.length == 0) gameIsStartable = false;

      return gameIsStartable;
    },
    stabilities() {
      let endResult = [];

      this.discStabilities.forEach((stability) => {
        if (stability.enabled) {
          for (let index = 0; index < stability.occurance; index++) {
            endResult.push(stability);
          }
        }
      });

      return endResult;
    },
    discs() {
      let endResult = [];

      this.discTypes.forEach((type) => {
        if (type.enabled) {
          for (let index = 0; index < type.occurance; index++) {
            endResult.push(type);
          }
        }
      });

      return endResult;
    },
    shots() {
      let endResult = [];

      this.shotTypes.forEach((shot) => {
        if (shot.enabled) {
          for (let index = 0; index < shot.occurance; index++) {
            endResult.push(shot);
          }
        }
      });

      return endResult;
    },
    releases() {
      let endResult = [];

      this.shotReleases.forEach((release) => {
        if (release.enabled) {
          for (let index = 0; index < release.occurance; index++) {
            endResult.push(release);
          }
        }
      });

      return endResult;
    },
  },
  methods: {
    generateThrow(bypassLoading) {
      if (!bypassLoading) {
        this.shotLoading = true;
        setTimeout(() => {
          this.shotLoading = false;
        }, 2000);
      }

      this.generatedThrow = {
        disc: this.discs[Math.floor(Math.random() * this.discs.length)],
        stability:
          this.stabilities[Math.floor(Math.random() * this.stabilities.length)],
        shotType: this.shots[Math.floor(Math.random() * this.shots.length)],
        release:
          this.releases[Math.floor(Math.random() * this.releases.length)],
      };
    },
    startGame() {
      if (this.gameIsStartable) {
        window.scrollTo(0, 0);
        setTimeout(() => {
          this.generatedThrow = null;
          this.generateThrow(true);
          this.ongoingGame = true;
        }, 600);
      }
    },
    endGame() {
      if (window.scrollY <= 50) {
        this.ongoingGame = false;
      } else {
        window.scrollTo(0, 0);
        setTimeout(() => {
          this.ongoingGame = false;
        }, 500);
      }
    },
    adjustOccurance(item) {
      if (item.enabled) {
        if (item.occurance < 5) {
          item.occurance++;
        } else {
          item.occurance = 1;
        }
      }
    },
    resetPreferences() {
      localStorage.removeItem("discDiceData");
      this.initialLoad();

      this.$store.dispatch("showMessage", {
        show: true,
        message: "Preferences reset",
        type: "success",
      });
    },
    saveGameState() {
      localStorage.setItem("discDiceData", JSON.stringify(this.$data));
    },
    initialLoad() {
      this.shotReleases = [
        {
          name: "Hyzer",
          occurance: 3,
          enabled: true,
        },
        {
          name: "Flat",
          occurance: 3,
          enabled: true,
        },
        {
          name: "Anhyzer",
          occurance: 3,
          enabled: true,
        },
      ];
      this.shotTypes = [
        {
          name: "Backhand",
          occurance: 3,
          enabled: true,
        },
        {
          name: "Forehand",
          occurance: 3,
          enabled: true,
        },
        {
          name: "Tomahawk",
          occurance: 3,
          enabled: true,
        },
        {
          name: "Thumber",
          occurance: 3,
          enabled: true,
        },
        {
          name: "Grenade",
          occurance: 3,
          enabled: true,
        },
      ];
      this.discTypes = [
        {
          name: "Putter",
          occurance: 3,
          enabled: true,
        },
        {
          name: "Midrange",
          occurance: 3,
          enabled: true,
        },
        {
          name: "Fairway Driver",
          occurance: 3,
          enabled: true,
        },
        {
          name: "Distance Driver",
          occurance: 3,
          enabled: true,
        },
      ];
      this.discStabilities = [
        {
          name: "Understable",
          occurance: 3,
          enabled: true,
        },
        {
          name: "Straight",
          occurance: 3,
          enabled: true,
        },
        {
          name: "Overstable",
          occurance: 3,
          enabled: true,
        },
      ];

      localStorage.setItem("discDiceData", JSON.stringify(this.$data));
    },
  },
  beforeMount() {
    if (localStorage.getItem("discDiceData")) {
      const gameData = JSON.parse(localStorage.getItem("discDiceData"));
      const gameVersion = this.gameVersion;

      if (gameVersion != gameData.gameVersion) {
        return this.initialLoad();
      }

      this.ongoingGame = gameData.ongoingGame;
      this.shotTypes = gameData.shotTypes;
      this.shotReleases = gameData.shotReleases;
      this.discTypes = gameData.discTypes;
      this.discStabilities = gameData.discStabilities;
    } else {
      this.initialLoad();
    }
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";
.slide-fade-enter-active {
  transition: all 0.6s ease;
}
.slide-fade-leave-active {
  transition: all 0.6s ease;
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100%);
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0;
}
.slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-150%);
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0;
}

.disc-dice {
  padding: 0px 18px 48px 18px;
  position: relative;
}

.active-game {
  min-height: 70vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  h3 {
    margin: 0;
    text-align: center;
  }
  p {
    text-align: center;
  }

  .generated-shot {
    h3 {
      display: block;
      text-align: center;
    }
  }

  .throw-text {
    margin-top: 0;
    text-align: center;
    margin-bottom: 12px;
    font-size: 16px;

    &.second {
      margin-top: 32px;
    }
  }

  .active-header {
    display: flex;
    position: relative;
    padding-top: 24px;
    .back {
      width: 25%;
      display: flex;
      align-items: center;
      font-size: 16px;
      cursor: pointer;
      svg {
        margin-right: 4px;
      }
    }
    .spacer {
      width: 20%;
    }

    .active-title {
      width: 50%;
      font-size: 16px;
    }
  }
}

h1 {
  text-align: center;
  padding-top: 24px;
}

h3 {
  margin-top: 40px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  line-height: 24px;

  span {
    font-size: 14px;
    line-height: 14px;
  }
}

.sub-text {
  margin-top: 12px;
  @include Gilroy-Regular;
  text-align: center;
  font-size: 20px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 0px;
    border-bottom: 1px solid $cloud;

    &:last-of-type {
      border-bottom: none;
    }

    &.disabled {
      .name {
        h5 {
          color: $sleet;
        }
      }

      .occurence {
        .button {
          background: $sleet !important;
        }
      }
    }

    .name {
      padding-left: 10px;
      cursor: pointer;
      user-select: none;

      h5 {
        transition: all ease 0.3s;
      }
    }

    .occurence {
      margin-left: auto;

      .button {
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 26px;
        border-radius: 4px;
        transition: all ease 0.3s;
        user-select: none;
        cursor: pointer;

        &.very-low {
          background: $sunrise;
        }
        &.low {
          background: #ffd266;
        }
        &.medium {
          background: $grass;
        }
        &.high {
          background: #ff73aa;
        }
        &.very-high {
          background: $dusk;
        }
        p {
          text-align: center;
          text-transform: uppercase;
          @include Gilroy-Bold;
          font-size: 12px;
          color: white;
          letter-spacing: 0.6px;
        }
      }
    }
  }
}
.generate-throw {
  margin-top: 32px;

  .end-game {
    color: $twilight;
    cursor: pointer;
    transition: all ease 0.3s;
    margin: 32px 0px 18px 0;
    user-select: none;
    &:hover {
      color: $twilightshade;
    }
  }
}

.game-not-startable {
  margin: 32px 0 48px 0;
  text-align: center;
}
.start-game {
  margin-top: 32px;

  .reset-preferences {
    color: $dusk;
    margin-top: 32px;
    text-align: center;
    cursor: pointer;
    user-select: none;
  }
}

.discfade-enter-active,
.discfade-leave-active {
  transition: opacity 0.5s;
}
.discfade-enter, .discfade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: $strom;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .disc-dice {
    max-width: 468px;
    margin: auto;
  }

  .slide-fade-enter-active {
    transition: all 0.6s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.6s ease;
  }
  .slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(0);
    opacity: 0;
    position: absolute;
    top: 32px;
    left: 0;
  }
  .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(0);
    opacity: 0;
    position: absolute;
    top: 32px;
    left: 0;
  }
}
</style>

